import BaseApiServices from "@/services/BaseApiServices";

class RankingAPIService extends BaseApiServices{


    async getCompanyRankings({ signal = null, defaultGameRanking = null }) {

        let url = `games/statistic/companyRankings`;

        if(defaultGameRanking){
            url+= '?defaultGameRanking='+defaultGameRanking;
        }

        const data = await this.getAxiosInstance().get(url, {signal});

        return data;
    }

    async getRankingUserDetails( { companyId, gameId, signal = null }) {

        const url = `admin/games/statistic/ranking/${companyId}/${gameId}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getRanking( { gameId = '', signal = null }) {

        if (gameId === null || gameId === undefined)
            gameId = '';

        const url = `games/statistic/ranking/${gameId}`;
        const data = await this.getAxiosInstance().get(url, {signal});

        return data;
    }
}

export default new RankingAPIService();
