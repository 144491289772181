export default {
    methods: {
        getPointsColor(item) {
            if (!item?.points) {
                return item?.points === 0 ? 'red' : 'orange'
            }
            return 'green';
        },
        getPointsText(item) {
            if (!item?.points) {
                return item?.points === 0 ? 0 : '--'
            }
            return item.points;
        }
    }

}
