
export default {
    methods:{
        getTimeForHumansString(seconds) {
            if(typeof +seconds !== 'number') return '';

            const time = this.dateHelper.getTimeForHumans(+seconds);

            const and = ` ${this.$t('common.and')} `;

            let started = false;
            let result = '';

            if(time.days){
                started = true;
                const timeElem = this.$tc('common.time.short.days', time.days);
                result = `${time.days} ${timeElem}`;
            }
            if(time.hours){
                const separator = started ? ((!isNaN(time.minutes ?? time.seconds))  ? ', ' : and ) : '';
                const timeElem = this.$tc('common.time.short.hours', time.hours);
                result += `${separator}${time.hours} ${timeElem}`;
                started = true;
            }
            if(time.minutes){
                const separator = started ? ( !isNaN(time.seconds)  ? ', ' : and ) : '';
                const timeElem = this.$tc('common.time.short.minutes', time.hours);
                result += `${separator}${time.minutes} ${timeElem}`;
                started = true;
            }
            if(time.seconds){
                const separator = started ?  and : '';
                const timeElem = this.$tc('common.time.short.seconds', time.hours);
                result += `${separator}${time.seconds} ${timeElem}`;
                started = true;
            }

            return result;
        }
    }

}
