<template>
    <v-container class="mt-0 pt-0">

        <div class="d-flex flex-wrap justify-start mb-4">
            <v-autocomplete
                class="title-post mb-4"
                v-model="rankingSelected"
                :items="companyRankings"
                :item-text="`title_${getLang}`"
                item-value="id"
                return-object
                style="max-width: 500px"
                hide-details
            />
        </div>
        <div>
            <v-data-table
                :headers="headers"
                :items="ranking"
                :page.sync="page"
                :items-per-page="+itemsPerPage"
                hide-default-footer
                class="elevation-1"
                @page-count="pageCount = $event"
                :item-class="itemRowBackground"
                mobile-breakpoint="0"
            >
                <template v-slot:item.name="{ item }">
                    <span v-text="getDisplayName(item)"></span>
                </template>

                <template v-slot:item.points="{ item }">
                    <v-chip
                        :color="getPointsColor(item)"
                        dark
                    >
                        {{ getPointsText(item) }}
                    </v-chip>
                </template>

                <template v-slot:item.timeT="{ item }">
                    <span v-text="getTimeForHumansString(item.timeT)"></span>
                </template>

            </v-data-table>

            <div class="d-flex pt-2 flex-wrap justify-space-between">
                <v-combobox
                    style="max-width: 6rem; margin-top: 15px"
                    v-model="itemsPerPage"
                    :items="[5, 10, 20, 50, 100, 500]"
                    :label="$t('games.ranking.itemsPerPages')"
                    dense
                ></v-combobox>

                <!--                <v-text-field
                    style="max-width: 6rem;"
                    :value="itemsPerPage"
                    :label="$t('games.ranking.itemsPerPages')"
                    type="number"
                    min="3"
                    max="300"
                    @input="itemsPerPage = parseInt($event, 10)"
                ></v-text-field>-->

                <v-pagination
                    v-model="page"
                    :length="pageCount"
                ></v-pagination>

            </div>

        </div>


    </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import dateTimeMixin from '@/mixins/dateTimeMixin'
import RankingAPIService from "@/services/RankingAPIService";
import itemPointsMixin from "@/mixins/itemPointsMixin";

export default {
    name: "Ranking",
    mixins:[dateTimeMixin, itemPointsMixin],
    data: function () {
        return {
            companyRankings: [],
            rankingSelected: null,
            ranking: [],
            page: 1,
            pageCount: 0,
            itemsPerPage: 50,
            headers: [
                {
                    text: this.$t('games.ranking.name'),
                    align: 'start',
                    value: 'name'
                },
                /*{ text: this.$t('games.ranking.lastname'), value: 'lastname', align: 'start' },*/
                { text: this.$t('games.ranking.points'), value: 'points', align: 'end' },
                { text: this.$t('games.ranking.time'), value: 'timeT', align: 'end' }
            ]
        }
    },
    async created() {

        this.$store.dispatch('loading/start');

        const gameId = this.$route.query.game;
        const defaultGameRanking = isNaN(gameId) ? null : +gameId;

        this.itemsPerPage = this.$store.state?.cache?.persistentInfo?.rankingItemsPerPage ?? 50;

        await this.getCompanyGamesRankings(defaultGameRanking);
        this.$store.dispatch('loading/end');


        this.$watch('rankingSelected', async (value)=>{
            const gameId = value?.game_id;

            this.$store.dispatch('loading/start');
            await this.getRanking(gameId);
            this.$store.dispatch('loading/end');
        });

        this.$watch('itemsPerPage', async (value)=>{
            const key =  'rankingItemsPerPage';
            await this.$store.dispatch('cache/getAndUpdatePersistentInfo', { key, value });
        });
    },
    methods: {
        itemRowBackground: function (item) {
            return item.id === this.getUserId ? 'table-current-user' : '';
        },
        async getCompanyGamesRankings(rankingSelected) {
            try {
                const controller = new AbortController();
                const signal = controller.signal;
                const defaultGameRanking = rankingSelected;

                const data = await RankingAPIService.getCompanyRankings({ signal, defaultGameRanking });

                this.initialize(data.data);

            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },
        async getRanking(gameId) {
            gameId = gameId || '';
            try {
                const controller = new AbortController();
                const signal = controller.signal;

                const data = await RankingAPIService.getRanking({ signal, gameId } );

                this.initialize(data.data);

            } catch (e) {
                if(e.name !== 'TypeError')
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },
        initialize(data) {

            if(data.companyRankings){
                this.companyRankings = data.companyRankings;

                if(data.defaultRanking)
                    this.rankingSelected = data.defaultRanking;

            }

            if(data.ranking)
                this.ranking = data.ranking;

        },
        getDisplayName(item){

            return `${ (item?.name ?? '').trim() } ${ (item?.lastname ?? '').trim() }`
        }
    },
    computed:{
        ...mapGetters(['getUserId']),
        ...mapGetters("languages", ['getLang'])
    },
    destroyed() {

    }
}
</script>

<style lang="scss" >
.table-current-user {
 background-color: #46d5ab;
}

</style>
